<template>
  <div class="lotto3-content">
    <div class="headnav"><i class="icon" @click="$router.go(-1)"></i>Shinda 3 Winning Mode</div>
    <div class="desc">
      <div class="text1">Choose The Winning Mode of Your Bet</div>
      <div class="text2">
        <p>There are two different prize structure for the draw:</p>
        <p>1. The any order odds prize chart</p>
        <p>2.The straight order odds prize chart</p>
      </div>
      <div class="text3">Users must choose one winning mode for their bets</div>
    </div>
    <div class="table-info">
      <div class="payouts">
        <div class="title">
          <span>Shinda 3 Odds & Payout Table</span>
        </div>

        <van-tabs class="tabs">
          <van-tab title="Any Order">
            <div class="desc">
              <div class="text1">Best Odds: 1:300</div>
              <div class="text2">Any Order Odds & Payout Table</div>
              <div class="text3">
                <span>By choosing Any Order, </span>
                <span class="green">you'll win by matching 1 number/ 2numbers/ 3 numbers in any order.</span>
              </div>
            </div>
            <PayoutTable class="payout-table" :tableType="1"></PayoutTable>
          </van-tab>
          <van-tab title="Straight Order">
            <div class="desc">
              <div class="text1">Grand Wins: <br>350X of Your Bet Amount Win Upto KSH350,000</div>
              <div class="text2">Straight Order Odds & Payout Table</div>
              <div class="text3">
                <span>By choosing Right Order, </span>
                <span class="green">you'll win by matching 2 number/ 3 numbers in straight order.</span>
              </div>
            </div>
            <PayoutTable class="payout-table" :tableType="2"></PayoutTable>
          </van-tab>
        </van-tabs>
        <div class="tabs-tip">
          <div class="tabs-tip1">Best Odds</div>
          <div class="tabs-tip2">Grand Wins</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import PayoutTable from "../components/PayoutTable";

    export default {
        components: {
          PayoutTable,
        },
        data() {
            return {
                playType: 0,
                betData: {}
            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";

  @lucky-margin: 60px;

  .lotto3-content {
    //background-color: @theme-color;
    padding-bottom: 6px;
    margin-top: 104px;
    min-height: @content-min-height;
    .desc{
      padding: @margin @margin 0;
      .text1{
        font-size: 28px;
        font-weight: bold;
      }
      .text2{
        color: #737373;
        font-size: 24px;
        text-align: left;
        margin-top: 30px;
      }
      .text3{
        margin-top: 30px;
        color: #737373;
        font-size: 24px;
        text-align: left;
      }
    }
    /deep/ .tabs {
      .van-tab {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #A0A0A0;
        line-height: 36px;
      }

      .van-tabs__wrap {
        height: 92px;
        border-bottom: 1px solid #F0F0F0;
        .van-tabs__nav--line{
          padding-bottom: 0;
        }
      }

      .van-tabs__line {
        width: 250px;
        height: 2px;
        background-color: #3C3C3C;
        border-radius: 0;
        bottom: 0;
      }

      .van-tab--active {
        font-family: Arial-Black, Arial;
        color: #3C3C3C;
        line-height: 36px;
      }
    }
    .table-info {
      margin: @margin;
      background-color: #E5F2ED;
      border-radius: 30px;
      overflow: hidden;
      position: relative;
      .title {
        padding: 30px 0;
        font-size: 34px;
        background-color: #CEE7DD;

        span{
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #3C3C3C;
          position: relative;
          i{
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-left: 10px;
            content: "";
            background-image: url(../assets/icon/icon-wh.png);
            background-size: cover;
            vertical-align: sub;
          }
        }
      }
      .tabs-tip{
        width: 100%;
        position: absolute;
        top: 140px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        div{
          background-color: #FFE600;
          color: #F55000;
          font-size: 20px;
          font-weight: bold;
          border-radius: 16px;
          padding: 2px 20px;
        }
      }
      /* .tabs-tip1{
        left: 94px;
      }
      .tabs-tip2{
        left: 430px;
      } */
      /deep/.van-tabs__wrap{
        margin-top: 60px;
        border-bottom: 1px solid #CEE7DD!important;
        .van-tabs__nav{
          background-color: #E5F2ED;
        }
      }
      .van-tabs__content{
        .desc{
          padding: 0 60px;
          .text1{
            font-size: 24px;
            font-weight: bold;
            color: #F55000;
            margin-top: 20px;
          }
          .text2{
            font-size: 28px;
            font-weight: bold;
            color: #3C3C3C;
            margin-top: 20px;
          }
          .text3{
            margin-top: 20px;
            font-size: 24px;
            color: #737373;
            .green{
              color: #008250;
            }
          }
        }
      }
      .payout-table {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .explanation {
        display: flex;
        justify-items: flex-start;

        .m-pesa {
          width: 320px;
          height: 489px;
        }

        .sms {
          width: 320px;
          height: 489px;
        }
      }
    }

    .quickBtn{
      display: flex;
      align-items: center;
      justify-items: flex-start;
      justify-content: space-between;
      .item{
        border: 1px solid #008250;
        width: 208px;
        padding: 24px 0;
        font-size: 28px;
        font-weight:bold;
        border-radius: 40px;
      }
      .active{
        background-color: #008250;
        color: #FFF;
      }
    }

    .successPopup{
      background-color: #FFF;
      border-radius: 48px 48px 0px 0px;
      padding: 64px 34px;
      font-size: 32px;
      position: relative;
      .closeIcon{
        position: absolute;
        top: 32px;
        right: 32px;
        width: 40px;
        height: 40px;
        background-image: url(../assets/img_close3.png);
        background-size: contain;
      }
      .top{
        .icon{
          img{
            width: 160px;
            height: 160px;
          }
        }
        .text1{
          margin-top: 34px;
          color: #008250;
          font-weight: bold;
        }
        .text2{
          font-size: 28px;
          margin-top: 18px;
          span{
            font-weight: bold;
            color: #F55000;
          }
        }
      }
      .bottom{
        font-size: 28px;
        margin-top: 30px;
        .item{
          display: flex;
          padding: 34px 0;
          border-bottom: 1px solid #F0F0F0;
          .label{
            color: #737373;
          }
          .value{
            flex: 1;
            text-align: right;
            color: #3C3C3C;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="g_div_table payout-table">

    <table class="table" v-if="tableType==1">
      <thead>
      <tr class="title-th">
        <th class="a">Match<br/>Number</th>
        <th class="b">Wager<br/>(KSH)</th>
        <th class="c">Odds</th>
        <th class="d">Multiplier</th>
        <th class="e">Winnings<br/>(KSH)</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(payout, index) in payouts" :key="index">
        <td class="a">{{payout.field1}}</td>
        <td class="b">{{payout.field2}}</td>
        <td class="c">{{payout.field3}}</td>
        <td class="d">{{payout.field4}}</td>
        <td class="e">{{payout.field5}}</td>
      </tr>
      </tbody>
    </table>

    <table class="table" v-if="tableType==2">
      <thead>
      <tr class="title-th">
        <th class="a">Wager<br/>(KSH)</th>
        <th class="b">My<br/>Number</th>
        <th class="c">Winning<br/>Numbers</th>
        <th class="d">Odds</th>
        <th class="e">Multiplier</th>
        <th class="f">Winnings<br/>(KSH)</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(payout, index) in payouts" :key="index">
        <td class="a">{{payout.field1}}</td>
        <td class="b" v-html="payout.field2"></td>
        <td class="c">{{payout.field3}}</td>
        <td class="d">{{payout.field4}}</td>
        <td class="e">{{payout.field5}}</td>
        <td class="f">{{payout.field6}}</td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
    export default {
        name: "PayoutTable",
        props: ['tableType'],
        data() {
            return {
              payouts: []
            }
        },
        created() {
          if(this.tableType==1){
            this.payouts = [
              {field1: "Match 1 Number",field2: "20",field3: "1:2",field4: "1.1x",field5: "22"},
              {field1: "Match 2 Number",field2: "20",field3: "1:7",field4: "2.2x",field5: "44"},
              {field1: "Match 3 Number",field2: "20",field3: "1:167",field4: "18x",field5: "360"},
              {field1: "Match 1 Number",field2: "1000",field3: "1:2",field4: "1.1x",field5: "1,100"},
              {field1: "Match 2 Number",field2: "1000",field3: "1:7",field4: "2.2x",field5: "2,200"},
              {field1: "Match 3 Number",field2: "1000",field3: "1:167",field4: "18x",field5: "18,000"},
            ]
          }else{
            this.payouts = [
              {field1: "20",field2: "<span class='winnerColor'>1</span>,2,3",field3: "1,3,2",field4: "1:4",field5: "1.2x",field6: "24"},
              {field1: "20",field2: "1,<span class='winnerColor'>2</span>,<span class='winnerColor'>3</span>",field3: "5,2,3",field4: "1:37",field5: "12x",field6: "240"},
              {field1: "20",field2: "<span class='winnerColor'>1</span>,<span class='winnerColor'>2</span>,<span class='winnerColor'>3</span>",field3: "1,2,3",field4: "1:1000",field5: "333x",field6: "6,660"},
              {field1: "1000",field2: "<span class='winnerColor'>1</span>,2,3",field3: "1,3,2",field4: "1:4",field5: "1.2x",field6: "12,00"},
              {field1: "1000",field2: "1,<span class='winnerColor'>2</span>,<span class='winnerColor'>3</span>",field3: "5,2,3",field4: "1:37",field5: "12x",field6: "12,000"},
              {field1: "1000",field2: "<span class='winnerColor'>1</span>,<span class='winnerColor'>2</span>,<span class='winnerColor'>3</span>",field3: "1,2,3",field4: "1:1000",field5: "333x",field6: "333,000"},
            ]
          }
        },
        methods: {}
    }
</script>

<style scoped lang="less">
  @import "../styles/var";
  .payout-table {

    /deep/ .table {
      border-spacing: 0;
      border-collapse: collapse;
      width: 100%;
      .winnerColor{
        color: red;
      }
      tr {
        height: 86px;
        border-bottom: 1px solid #CEE7DD;
      }

      td {
        height: 25px;
        font-size: 22px;
        font-family: ArialMT;
        color: #3C3C3C;
        line-height: 26px;
        text-align: left;
      }

      .title-th {
        height: 86px;
        background: #CEE7DD;
        font-size: 22px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #737373;
        line-height: 26px;
        text-align: left;
        border: none;
      }

      .a {
        width: 100px;
        padding-left: @td-padding;
        text-align: left;
      }

      .b {
        width: 100px;
      }

      .c {
        width: 120px;
      }

      .d {
        width: 100px;
      }

      .e {
        width: 120px;
      }

      .f {
        width: 120px;
        padding-right: @td-padding;
      }
    }
  }
</style>
